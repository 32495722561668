<template>
  <div>
    <el-card class="mb15" shadow="hover">
      <el-row>
        <el-input
          :placeholder="$t('search')"
          clearable
          v-model="filter.search"
          @keyup.enter.native="get_list"
        ></el-input>
      </el-row>
      <el-row class="mt20" type="flex" :gutter="20" align="middle">
          <span class="text">{{$t('memberManagement.active')}}:</span>
          <el-radio-group size="medium" v-model="filter.active">
               <el-radio label>{{$t('all')}}</el-radio>
              <el-radio :label="0">{{active(0)}}</el-radio>
              <el-radio :label="1">{{active(1)}}</el-radio>
          </el-radio-group>
      </el-row>
      <el-row class="mt20" type="flex" align="middle">
        <el-button @click="get_list" type="primary" class="search">{{
          $t("search_zh")
        }}</el-button>
        <el-button @click="reset" type="info">{{
          $t("btnTip.reset")
        }}</el-button>
      </el-row>
    </el-card>
    <el-card shadow="hover">
      <div class="flexBC mb20">
        <el-button type="primary" @click="handleClick('add')">{{
          $t("btnTip.add")
        }}</el-button>
        <div>
          <el-button type="primary" @click="outExe">{{ $t("outExe") }}</el-button>
          <el-button
          type="primary"
          @click="outExeAll('memberList')"
          >전체출력</el-button>
        </div>
      </div>
      <my-table
        :columns="columns"
        :data="list_data.list"
        :showIndex="false"
        :showSelection="false"
        :cellStyle="{ padding: '6px 0' }"
        :headerCellStyle="{
          background: 'rgba(51, 55, 68, 1)',
          color: '#fff',
        }"
        @emitSelection="allSelect"
        @sortChange="sort_change"
      >
      <template v-slot:gender="slotProps">
          <div>
            {{ gender(slotProps.callback.row.gender) }}
          </div>
        </template>
        <template v-slot:address="slotProps">
          <div>
            {{slotProps.callback.row.address}} {{slotProps.callback.row.address_detail}}
          </div>
        </template>
        <template v-slot:favorite_category="slotProps">
          <div>
            <span
              class="span"
              v-for="(item, index) in slotProps.callback.row.favorite_category"
              :key="index"
              ><span v-if="index!==0">/</span>{{ favorite_category(item) }}</span
            >
          </div>
        </template>
        <template v-slot:is_email="slotProps">
          <div>
            {{ yse_or_no(slotProps.callback.row.is_email) }}
          </div>
        </template>
        <template v-slot:is_sms="slotProps">
          <div>
            {{ yse_or_no(slotProps.callback.row.is_sms) }}
          </div>
        </template>
        <template v-slot:active="slotProps">
          <div>
            <el-tag :type="activeTag(slotProps.callback.row.active)">{{
              active(slotProps.callback.row.active)
            }}</el-tag>
          </div>
        </template>
        <template v-slot:operation="slotProps">
            <el-tooltip class="item" effect="dark" :content="$t('btnTip').check" placement="top">
             <el-button
              @click="handleClick('check',slotProps.callback.row)"
              icon="el-icon-view"
              type="success"
              size="mini"
            ></el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('tToScore')"
            placement="top"
          >
            <el-button
              @click="handleClick('toScore', slotProps.callback.row)"
              type="success"
              size="mini"
              >{{ $t("tToScore") }}</el-button
            >
          </el-tooltip>
          <el-button
              @click="handleClick('toHistory', slotProps.callback.row)"
              type="success"
              size="mini"
              >{{ $t("tToHistory") }}</el-button
            >
          <el-tooltip
            class="item"
            effect="dark"
            :content="
              slotProps.callback.row.active === 0
                ? $t('account').active1
                : $t('account').active0
            "
            placement="top"
          >
            <el-button
              @click="handleClick('active', slotProps.callback.row)"
              :type="
                slotProps.callback.row.active === 0 ? 'success' : 'warning'
              "
              size="mini"
              >{{
                slotProps.callback.row.active === 0
                  ? $t("account").active1
                  : $t("account").active0
              }}</el-button
            >
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('btnTip').resetPW"
            placement="top"
          >
            <el-button
              @click="handleClick('edit', slotProps.callback.row)"
              type="info"
              size="mini"
              >{{ $t("btnTip").resetPW }}</el-button
            >
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="포인트부여"
            placement="top"
          >
          <el-button
              @click="handleClick('change', slotProps.callback.row)"
              type="primary"
              size="mini"
              icon="el-icon-edit-outline"
              ></el-button
            >
          </el-tooltip>
          <el-button
              @click="del(slotProps.callback.row.id)"
              type="danger"
              size="mini"
              >{{ $t("btnTip").delete }}</el-button>
        </template>
      </my-table>
      <page
        :total="list_data.total"
        :page_size.sync="pageSize"
        :page.sync="page"
      />
    </el-card>
    <el-dialog :title="title" :visible.sync="dialogFormVisible" width="500px">
      <form-page
        :ref-obj.sync="formInfo.ref"
        :data="formInfo.data"
        :field-list="formInfo.fieldList"
        :rules="formInfo.rules"
        :label-width="formInfo.labelWidth"
        :list-type-info="listTypeInfo"
        :disabled="formInfo.disabled"
      ></form-page>
      <span slot="footer" class="dialog-footer">
        <el-button @click="done('managerForm')" type="primary" class="ml30">{{
          buttonText
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mixin_list } from "@/mixins";
import page from "@/components/page";
import myTable from "@/components/table";
import FormPage from "@/components/FormPage";
import { memberList, memberChange, addMember, editMember, memberDelete } from "@/api/index";
const formInfoData = {
  password: "",
};
export default {
  name: "memberManagement",
  mixins: [mixin_list(memberList)],
  components: {
    myTable,
    FormPage,
    page,
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if(!rule.required) {
        callback()
        return
      }
      const reg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/
      if (value === '') {
        callback(new Error(this.$t('memberManagement').passwordRule));
      } else {
        if(!reg.test(value)){
          callback(new Error(this.$t('memberManagement').passwordRule));
        }else if (this.formInfo.data.password !== '') {
          callback();
        }
      }
    };
    var validatePass1 = (rule, value, callback) => {
      if(!rule.required) {
        callback()
        return
      }
      const reg = /^[0-9a-zA-Z]{6,12}$/;
      if (value === '') {
        callback(new Error('6-12자 이내 영문, 숫자 입력'));
      } else if(!reg.test(value)) {
        callback(new Error('6-12자 이내 영문, 숫자 입력'));
      }else{
        callback();
      }
    };
    return {
      filter: {
        search: "",
        active:""
      },
      columns: [
        {
          label: "ID",
          sortable: true,
          prop: "id",
          width: 80,
        },
        {
          label: this.$t("memberManagement.create_time"),
          sortable: true,
          prop: "create_time",
          width:100
        },
        {
          label: this.$t("memberManagement.username"),
          sortable: true,
          prop: "username",
        },
        {
          label: this.$t("memberManagement.real_name"),
          sortable: true,
          prop: "real_name",
        },
        {
          label: this.$t("memberManagement.email"),
          sortable: true,
          prop: "email",
        },
        {
          label: this.$t("memberManagement.gender"),
          sortable: true,
          slot:"gender",
          prop: "gender",
          width:80
        },
        {
          label: this.$t("memberManagement.birth"),
          sortable: true,
          prop: "birth",
        },
        {
          label: this.$t("memberManagement.phone"),
          sortable: true,
          prop: "phone",
        },
        {
          label: this.$t("memberManagement.address"),
          sortable: 'custom',
          prop: "address",
          slot:"address"
        },
        {
          label: this.$t("memberManagement.score"),
          sortable: true,
          prop: "score",
        },
        // {
        //   label: this.$t("memberManagement.favorite_category"),
        //   slot: "favorite_category",
        //   prop: "favorite_category",
        // },
        // {
        //   label: this.$t("memberManagement.is_email"),
        //   sortable: true,
        //   slot: "is_email",
        //   prop: "is_email",
        //   width:150
        // },
        // {
        //   label: this.$t("memberManagement.is_sms"),
        //   sortable: true,
        //   slot: "is_sms",
        //   prop: "is_sms",
        // },
        {
          label: this.$t("memberManagement.active"),
          sortable: true,
          slot: "active",
          prop: "active",
        },
        {
          label: this.$t("operation"),
          prop: "",
          width: 580,
          slot: "operation",
          fixed:'right'
        },
      ],
      formInfo: {
        ref: null,
        disabled: false,
        data: formInfoData,
        fieldList: [
          {
            label: this.$t("memberManagement").username,
            value: "username",
            type: "input",
            width: "260",
            className: "el-form-block",
            required: true,
            hidden: false,
            validator: validatePass1,
          },
          {
            label: this.$t("memberManagement").real_name,
            value: "real_name",
            type: "input",
            width: "260",
            className: "el-form-block",
            required: true,
            hidden: false
          },
          {
            label: this.$t("memberManagement").password,
            value: "password",
            type: "input",
            width: "260",
            className: "el-form-block",
            // required: true,
            hidden: false,
            message:this.$t("memberManagement").passwordRule,
            validator: validatePass,
          },
          {
            label: this.$t("memberManagement").score,
            value: "score",
            type: "input",
            width: "260",
            className: "el-form-block",
            required: true,
            hidden: false
          },
        ],
        rules: {
           
        },
        labelWidth: "120px",
      },
      listTypeInfo: {},
      dialogFormVisible: false,
      which: "",
    };
  },
  computed: {
    title() {
      let title
      switch (this.which) {
        case "add":
          title = this.$t("btnTip").add;
          break;
        case "edit":
          title = this.$t("btnTip").edit;
          break;
        case "change":
          title = '포인트부여';
          break;
      }
      return title
    },
    buttonText() {
      let buttonText
      switch (this.which) {
         case "add":
          buttonText = this.$t("btnTip").submit;
          break;
        case "edit":
          buttonText = this.$t("btnTip").submit;
          break;
        case "change":
          buttonText = this.$t("btnTip").submit;
          break;
      }
      return buttonText
    },
  },
  methods: {
    favorite_category(favorite_category){
      switch(favorite_category){
          case 1:
          return this.$t('memberManagement').favorite_category1
          case 2:
          return this.$t('memberManagement').favorite_category2
          case 3:
          return this.$t('memberManagement').favorite_category3
          case 4:
          return this.$t('memberManagement').favorite_category4
          case 5:
          return this.$t('memberManagement').favorite_category5
          case 6:
          return this.$t('memberManagement').favorite_category6
          case 7:
          return this.$t('memberManagement').favorite_category7
      }
    },
    active(active) {
      switch (active) {
        case 0:
          return this.$t("account").active0;
        case 1:
          return this.$t("account").active1;
      }
    },
    activeTag(active) {
      switch (active) {
        case 0:
          return "danger";
        case 1:
          return "success";
      }
    },
    yse_or_no(yse_or_no) {
      switch (yse_or_no) {
        case 0:
          return this.$t("memberManagement").no;
        case 1:
          return this.$t("memberManagement").yes;
      }
    },
    gender(gender){
      switch(gender){
        case 0:
          return this.$t("memberManagement").gender0
        case 1:
          return this.$t("memberManagement").gender1
        case 2:
          return this.$t("memberManagement").gender2
      }
    },
    async handleClick(type, data) {
      switch (type) {
        case "add":
               this.formInfo.data = {
                username: "",
                real_name: "",
                password: "",
              };
              this.formInfo.fieldList[0].required = true
              this.formInfo.fieldList[0].hidden = false
              this.formInfo.fieldList[1].required = true
              this.formInfo.fieldList[1].hidden = false
              this.formInfo.fieldList[2].required = true
              this.formInfo.fieldList[2].hidden = false
              this.formInfo.fieldList[3].required = true
              this.formInfo.fieldList[3].hidden = false
              this.initRules();
              this.formInfo.disabled = type !== "check" ? false : true;
              this.dialogFormVisible = true;
              this.which = type;
              break
        case "check":
               this.$router.push(`/member/detail?id=${data.id}`)
              break
          case "toScore":
               this.$router.push(`/member/${data.id}/score`)
              break
          case "toHistory":
               this.$router.push(`/member/${data.id}/finishhistory`)
              break
        case "active":
          await memberChange({
            id: data.id,
            active: data.active === 0 ? 1 : 0,
          });
          this.get_list();
          break;
        case "edit":
          this.formInfo.data = {
            id: data.id,
            password: "",
          };
          this.formInfo.fieldList[0].required = false
          this.formInfo.fieldList[0].hidden = true
          this.formInfo.fieldList[1].required = false
          this.formInfo.fieldList[1].hidden = true
          this.formInfo.fieldList[2].required = true
          this.formInfo.fieldList[2].hidden = false
          this.formInfo.fieldList[3].required = false
          this.formInfo.fieldList[3].hidden = true
          this.initRules();
          this.formInfo.disabled = type !== "check" ? false : true;
          this.dialogFormVisible = true;
          this.which = type;
          break;
        case "change":
          this.formInfo.data = {
            id: data.id,
            username: data.username,
            real_name: data.real_name,
            score: data.score
          };
          this.formInfo.fieldList[0].required = true
          this.formInfo.fieldList[0].hidden = false
          this.formInfo.fieldList[1].required = false
          this.formInfo.fieldList[1].hidden = true
          this.formInfo.fieldList[2].required = false
          this.formInfo.fieldList[2].hidden = true
          this.formInfo.fieldList[3].required = true
          this.formInfo.fieldList[3].hidden = false
          this.initRules()
          this.formInfo.disabled = type !== "check" ? false : true;
          this.dialogFormVisible = true;
          this.which = type;
          break;
      }
    },
    done() {
      switch (this.which) {
        case "add":
          this.formInfo.ref.validate(async (valid) => {
            if (valid) {
              this.dialogFormVisible = false;
              await addMember(this.formInfo.data);
              this.get_list();
            }
          });
          break;
        case "edit":
          this.formInfo.ref.validate(async (valid) => {
            if (valid) {
              this.dialogFormVisible = false;
              await memberChange(this.formInfo.data);
              this.get_list();
            }
          });
          break;
        case "change":
          this.formInfo.ref.validate(async (valid) => {
            if (valid) {
              this.dialogFormVisible = false;
              await editMember(this.formInfo.data);
              this.get_list();
            }
          });
          break;
      }
    },
    async del(id) {
      this.$confirm(this.$t('sureDelete'), this.$t('tips'), {
        confirmButtonText: this.$t('btnTips').submit,
        cancelButtonText: this.$t('btnTips').cancel,
        type: 'warning'
      }).then(async () => {
        await memberDelete(id)
        this.get_list()
      }).catch({
      })
    },
  },
};
</script>
<style lang="scss" scoped>
.text{
  width:100px;
  font-size: 16px;
}
/deep/ label{
  margin-bottom:0!important
}
</style>